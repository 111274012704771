.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}
.shopDecorate .img-el-upload {
  width: 80px;
  height: 50px;
}
.shopDecorate .img-el-upload .el-upload {
  width: 100% !important;
  height: 100% !important;
}
.shopDecorate .el-switch__core {
  width: 45px !important;
}
.shopDecorate .phone_top {
  padding: 20px 13px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.shopDecorate .phone_top .setBox {
  border: 1px solid #dcdfe6;
  padding: 5px 8px;
  border-radius: 20px;
}
.shopDecorate .phone_bot {
  border-top: #000 solid 1px;
  padding: 10px 13px 20px;
}
.shopDecorate .phonebox {
  width: 375px;
  min-height: 500px;
  height: 750px;
  border: 1px solid #000;
  border-radius: 40px;
}
.shopDecorate .phonebox .top {
  position: relative;
  z-index: 100;
  cursor: pointer;
}
.shopDecorate .phonebox > .phone_content {
  padding: 0 13px;
  margin-bottom: 10px;
}
.shopDecorate .phonebox > .phone_content > div {
  position: relative;
  margin-bottom: 10px;
}
.shopDecorate .phonebox .phone_banner {
  min-height: 150px;
  text-align: center;
  background-color: #999;
}
.shopDecorate .phonebox .phone_application {
  width: 100%;
  min-height: 115px;
}
.shopDecorate .phonebox .phone_application .application_item {
  width: 25%;
  margin-bottom: 5px;
}
.shopDecorate .phonebox .phone_application .application_item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.shopDecorate .phonebox .pushBanner {
  width: 100%;
  height: 120px;
}
.shopDecorate .phonebox .Selected .course_list {
  padding: 10px 0;
}
.shopDecorate .phonebox .Selected .course_list img {
  width: 100px;
  height: 70px;
  margin-right: 8px;
  flex-shrink: 0;
}
.shopDecorate .phonebox .workPlace {
  padding: 10px 5px 20px;
}
.shopDecorate .phonebox .workPlace .course_list {
  padding: 10px 0;
}
.shopDecorate .phonebox .workPlace .course_list img {
  width: 100px;
  height: 70px;
  margin-right: 8px;
  flex-shrink: 0;
}
.shopDecorate .modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(203, 209, 255, 0.4);
  z-index: 99;
  width: 100%;
  height: 100%;
  border: 2px dashed #001bff;
  display: flex;
  justify-content: flex-end;
}
.shopDecorate .modal div {
  align-self: flex-end;
}
.shopDecorate .modal div img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}
.flexText {
  margin-left: 64px;
  width: 212px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
 /deep/ .el-select__tags .el-tag {
  min-width: 0!important;
}
 /deep/ .el-select__tags .el-tag .el-select__tags-text {
  min-width: 0!important;
}
